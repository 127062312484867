<template>
    <div class="app-container">
        <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
            <!--节点列表-->
            <el-tab-pane :label="$t('ops.consulManager.agentChecks')" :name="agentChecksTab" :key="agentChecksTab">
                <div class="filter-container">
                    <el-select @change="changeDC" v-model="currentDC">
                        <el-option
                                v-for="item in dcList"
                                :key="item"
                                :value="item"
                                :label="item">
                        </el-option>
                    </el-select>
                    <el-input :placeholder="$t('commons.searchPhrase')" v-model="searchPhrase"
                              maxlength="50"
                              style="width: 200px;" class="filter-item" clearable/>
                    <el-button class="filter-item" type="primary" icon="el-icon-refresh" :loading="loading" @click="getAgentChecks">
                        {{$t('commons.refresh') }}
                    </el-button>
                </div>
                <el-table v-loading="loading"
                          @row-click="viewServiceList"
                          row-class-name="row-pointer"
                        :data="agentChecks.filter(data => !searchPhrase || data.serviceName.toLowerCase().includes(searchPhrase.toLowerCase()))"
                        element-loading-text="Loading"
                        border
                        fit
                        highlight-current-row
                        stripe>
                    <el-table-column type="index" align="center" :label="$t('commons.index')" width="95">
                    </el-table-column>
                    <el-table-column :label="$t('ops.consulManager.serviceName')" width="220">
                        <template slot-scope="scope">
                            <span>{{ scope.row.serviceName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('ops.consulManager.serviceId')" width="300">
                        <template slot-scope="scope">
                            <span>{{ scope.row.serviceId }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('ops.consulManager.healthChecks')" width="100">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.status | statusTagFilter">{{ scope.row.status }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('ops.consulManager.serviceTags')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.serviceTags }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <!--服务列表选项卡-->
            <el-tab-pane v-if="serviceListTab" :label="$t('ops.consulManager.serviceList')" :name="serviceListTab" :key="serviceListTab" closable>
                <service-list v-bind:currentRow="currentRow"></service-list>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss">

    .row-pointer {
        cursor: pointer;
    }

</style>

<script>
    import ServiceList from '@/views/ruge/ops/consul/serviceList'
    import {getAgentChecks,getDatacenters} from '@/api/ruge/ops/consul'

    const defineTab = {
        agentChecksTab : "agentChecksTab",
        serviceListTab : "serviceListTab"
    }

    export default {
        name: 'ConsulManager',
        components: {ServiceList},
        filters: {
            // 返回状态tag效果
            statusTagFilter(status) {
                let str = '';
                if (status === 'PASSING') {
                    str = 'success';
                } else {
                    str = 'danger';
                }
                return str;
            },
        },
        data() {
            return {
                activeTabName : defineTab.agentChecksTab,
                serviceListTab: null,
                // 当前选中的datacenter，默认dc1
                currentDC: 'dc1',
                // datacenter列表
                dcList: [],
                loading: true,
                agentChecks: [],
                searchPhrase: undefined,
                currentRow : {
                    datacenter: '',
                    serviceName: '',
                }
            }
        },
        created() {
            this.getAgentChecks();
            this.getDCList();
        },
        computed: {
            agentChecksTab() {
                return defineTab.agentChecksTab;
            }
        },
        methods: {
            // 获取datacenter列表
            getDCList() {
                this.loading = true;
                getDatacenters().then(response => {
                    this.dcList = response;
                }).catch(error => {
                    console.log(`查询Datacenter失败，原因 => ${error}`)
                }).finally(() => {
                    this.loading = false;
                })
            },
            // 切换datacenter
            changeDC(val) {
                this.currentDC = val;
            },
            // 获取当前代理中服务实例的检查信息
            getAgentChecks() {
                this.loading = true;
                getAgentChecks(this.currentDC).then(response => {
                    // 返回对象转数组
                    let arr = [];
                    for (let key in response) {
                        arr.push(response[key]);
                    }
                    this.agentChecks = arr;
                }).catch(error => {
                    console.log(`查询失败，原因 => ${error}`)
                }).finally(() => {
                    this.loading = false;
                })
            },
            // 查看服务列表
            viewServiceList(row) {
                this.serviceListTab = defineTab.serviceListTab;
                this.currentRow.datacenter = this.currentDC;
                this.currentRow.serviceName = row.serviceName;
                this.activeTabName = defineTab.serviceListTab;
            },
            // 关闭项Tab
            removeTab() {
                this.activeTabName = defineTab.agentChecksTab;
                this.serviceListTab = null;
            }
        }
    }
</script>

