import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取数据中心列表
 */
export function getDatacenters() {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/consul/findDatacenters`,
    method: 'get',
  })
}

/**
 * 获取当前代理中服务实例的检查信息
 */
export function getAgentChecks() {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/consul/findAgentChecks`,
    method: 'get',
  })
}

/**
 * 获取指定数据中心下注册的节点列表
 */
export function getNodeList(datacenter) {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/consul/findNodes/${datacenter}`,
    method: 'get',
  })
}

/**
 * 获取指定数据中心下对应服务的信息
 */
export function getServiceList(data) {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/consul/findHealthServices/${data.serviceName}/${data.datacenter}`,
    method: 'get',
  })
}

/**
 * 切换服务的维护模式
 */
export function setMaintenance(serviceId, maintenanceEnabled, reason) {
  return request({
    url: envInfo.bgApp.opsPath + '/ops/consul/setMaintenance',
    method: 'post',
    data: {
      serviceId : serviceId,
      maintenanceEnabled : maintenanceEnabled,
      reason : reason,
    }
  })
}

/**
 * 服务注销
 */
export function deregister(serviceId) {
  return request({
    url: envInfo.bgApp.opsPath + '/ops/consul/deregister',
    method: 'post',
    data: {
      serviceId : serviceId,
    }
  })
}

