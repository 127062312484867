<template>
    <div>
        <el-row>
            <el-col :span="6">
                <el-tag size="middle">{{ $t('ops.consulManager.serviceName') }}: {{ currentRow.serviceName }}</el-tag>
            </el-col>
            <el-col :span="6" :offset="12">
                <div class="filter-container" align="right">
                    <el-input :placeholder="$t('commons.searchPhrase')" maxlength="50" v-model="searchPhrase"
                              style="width: 200px;" size="small" class="filter-item" clearable/>
                    <el-button class="filter-item" size="small" type="primary" icon="el-icon-refresh" :loading="loading" @click="getServiceList">
                        {{$t('commons.refresh') }}
                    </el-button>
                </div>
            </el-col>
        </el-row>

        <el-table v-loading="loading"
                  :data="serviceList.filter(data => !searchPhrase || data.service.id.toLowerCase().includes(searchPhrase.toLowerCase() || data.node.node.toLowerCase().includes(searchPhrase.toLowerCase())) || data.node.address.toLowerCase().includes(searchPhrase.toLowerCase()))"
                  element-loading-text="Loading"
                  border
                  fit
                  highlight-current-row
                  stripe>
            <el-table-column type="index" align="center" :label="$t('commons.index')" width="95">
            </el-table-column>
            <el-table-column :label="$t('ops.consulManager.serviceId')" width="300">
                <template slot-scope="scope">
                    <span>{{ scope.row.service.id }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('ops.consulManager.nodeName')">
                <template slot-scope="scope">
                    <span>{{ scope.row.node.node }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('ops.consulManager.address')">
                <template slot-scope="scope">
                    <span>{{ scope.row.service.address }}:{{ scope.row.service.port }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('ops.consulManager.nodeChecks')">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.checks[0].status | statusTagFilter">{{ scope.row.checks[0].status }}</el-tag>
                    <template v-if="scope.row.checks[0].notes !== ''">
                        <span>[{{ scope.row.checks[0].notes }}]</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('ops.consulManager.serviceChecks')">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.checks[1].status | statusTagFilter">{{ scope.row.checks[1].status }}</el-tag>
                    <template v-if="scope.row.checks[1].notes !== ''">
                        <span>[{{ scope.row.checks[1].notes }}]</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('commons.actions')" align="center" width="160">
                <template slot-scope="scope">
                    <template v-if="scope.row.checks[1].status === 'PASSING'">
                        <el-tooltip :content="$t('ops.consulManager.enableMaintenance')" placement="bottom" :open-delay="openDelay">
                            <el-button
                                    size="mini"
                                    icon="el-icon-video-pause"
                                    type="warning"
                                    circle
                                    :disabled="loading"
                                    @click="setMaintenance(scope.row, true)">
                            </el-button>
                        </el-tooltip>
                    </template>
                    <template v-else>
                        <el-tooltip :content="$t('ops.consulManager.cancelMaintenance')" placement="bottom" :open-delay="openDelay">
                            <el-button
                                    size="mini"
                                    icon="el-icon-video-play"
                                    type="success"
                                    circle
                                    :disabled="loading"
                                    @click="setMaintenance(scope.row, false)">
                            </el-button>
                        </el-tooltip>
                    </template>
                    <template>
                        <el-tooltip :content="$t('ops.consulManager.deregister')" placement="bottom" :open-delay="openDelay">
                            <el-button
                                    size="mini"
                                    icon="el-icon-delete"
                                    type="danger"
                                    circle
                                    :disabled="loading"
                                    @click="deregister(scope.row)">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content="$t('commons.details')" placement="bottom" :open-delay="openDelay">
                            <el-button
                                    size="mini"
                                    icon="el-icon-document"
                                    type="primary"
                                    circle
                                    @click="showServiceDetail(scope.row)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <!-- 显示服务详情内容对话框 -->
        <el-dialog :visible.sync="dialogVisible">
            <template slot="title">
                <title-icon/>{{ $t('commons.details') }}
            </template>
            <pre class="service-detail-pre">{{messageContent}}</pre>
            <div slot="footer">
                <el-button type="primary" @click="closeServiceDetail">{{$t('commons.close')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>

    .service-detail-pre {
        height: 50vh;
        overflow: auto;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        padding: 5px;
        margin: 0;
    }

</style>

<script>
    import {getServiceList, setMaintenance, deregister} from '@/api/ruge/ops/consul'

    export default {
        filters: {
            // 返回状态tag效果
            statusTagFilter(status) {
                let str = '';
                if (status === 'PASSING') {
                    str = 'success';
                } else {
                    str = 'danger';
                }
                return str;
            },
        },
        data() {
            return {
                // Tooltip显示延迟时间(毫秒)
                openDelay: 1000,
                loading: true,
                serviceList: [],
                searchPhrase: undefined,
                // 对话框是否可见，默认不可见
                dialogVisible: false,
                // 要显示的消息内容
                messageContent: undefined,
            }
        },
        props: {
            currentRow: {
                type: Object,
                required: true
            }
        },
        computed: {
            serviceName() {
                return this.currentRow.serviceName;
            },
            datacenter() {
                return this.currentRow.datacenter;
            }
        },
        watch: {
            serviceName() {
                this.getServiceList()
            },
            datacenter() {
                this.getServiceList()
            }
        },
        created() {
            this.getServiceList()
        },
        methods: {
            getServiceList() {
                this.loading = true
                getServiceList(this.currentRow).then(response => {
                    this.serviceList = response;
                }).catch(error => {
                    console.log(`查询失败，原因 => ${error}`)
                }).finally(() => {
                    this.loading = false;
                })
            },
            // 切换服务的维护模式
            setMaintenance(row, maintenanceEnabled) {
                this.$confirm(this.$t('ops.consulManager.setMaintenanceConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    setMaintenance(row.service.id, maintenanceEnabled, maintenanceEnabled ? '服务维护中' : '').then(() => {
                        this.getServiceList();
                        // 成功提示
                        this.$message({
                            message: this.$t('message.operationSuccess'),
                            type: 'success'
                        });
                    }).catch(error => {
                        console.log(`切换服务的维护模式失败，原因 => ${error}`);
                        // 失败提示
                        this.$message({
                            message: this.$t('message.operationFailed'),
                            type: 'error'
                        });
                    }).finally(() => {
                        this.loading = false;
                    })
                }).catch(error => {
                    console.log(`未切换，原因 => ${error}`)
                });
            },
            // 服务注销
            deregister(row) {
                this.$confirm(this.$t('ops.consulManager.deregisterConfirm2'), this.$t('ops.consulManager.deregisterConfirm1'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    deregister(row.service.id).then(() => {
                        this.getServiceList();
                        // 成功提示
                        this.$message({
                            message: this.$t('message.operationSuccess'),
                            type: 'success'
                        });
                    }).catch(error => {
                        console.log(`服务注销失败，原因 => ${error}`);
                        // 失败提示
                        this.$message({
                            message: this.$t('message.operationFailed'),
                            type: 'error'
                        });
                    }).finally(() => {
                        this.loading = false;
                    })
                }).catch(error => {
                    console.log(`未注销，原因 => ${error}`)
                });
            },
            // 查看服务详情内容
            showServiceDetail(row) {
                this.dialogVisible = true;
                this.messageContent = row;
            },
            //关闭模态框
            closeServiceDetail(){
                this.dialogVisible = false;
            },
        }
    }
</script>
