var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.agentChecksTab,
              attrs: {
                label: _vm.$t("ops.consulManager.agentChecks"),
                name: _vm.agentChecksTab,
              },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.changeDC },
                      model: {
                        value: _vm.currentDC,
                        callback: function ($$v) {
                          _vm.currentDC = $$v
                        },
                        expression: "currentDC",
                      },
                    },
                    _vm._l(_vm.dcList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { value: item, label: item },
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t("commons.searchPhrase"),
                      maxlength: "50",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchPhrase,
                      callback: function ($$v) {
                        _vm.searchPhrase = $$v
                      },
                      expression: "searchPhrase",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-refresh",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.getAgentChecks },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("commons.refresh")) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    "row-class-name": "row-pointer",
                    data: _vm.agentChecks.filter(
                      (data) =>
                        !_vm.searchPhrase ||
                        data.serviceName
                          .toLowerCase()
                          .includes(_vm.searchPhrase.toLowerCase())
                    ),
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    stripe: "",
                  },
                  on: { "row-click": _vm.viewServiceList },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      label: _vm.$t("commons.index"),
                      width: "95",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("ops.consulManager.serviceName"),
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.serviceName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("ops.consulManager.serviceId"),
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.serviceId))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("ops.consulManager.healthChecks"),
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm._f("statusTagFilter")(
                                    scope.row.status
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(scope.row.status))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("ops.consulManager.serviceTags") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.serviceTags))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.serviceListTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.serviceListTab,
                  attrs: {
                    label: _vm.$t("ops.consulManager.serviceList"),
                    name: _vm.serviceListTab,
                    closable: "",
                  },
                },
                [_c("service-list", { attrs: { currentRow: _vm.currentRow } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }